import {textToVoice} from "../network/voice";

class TextSpeaker {
    taskStack: {
        text:string,
        promise:Promise<ArrayBuffer>,
    }[] = []

    source: AudioBufferSourceNode | null = null


    addTask = (text: string) => {
        if(text){
            this.taskStack.push({
                text,
                promise:textToVoice(text,"zh-CN", "loli"),
            });
            if (!this.source) {
                this.speak();
            }
        }

    }
    private speak = async () => {
        if (this.taskStack.length === 0) {
            return;
        }
        const audioContext = new AudioContext()
        const source = audioContext.createBufferSource()
        this.source = source
        const task = this.taskStack.shift()!;
        const arrayBuffer = await task.promise
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
        source.buffer = audioBuffer;
        source.connect(audioContext.destination)
        source.start();
        source.onended = () => {
            this.source = null;
            this.speak(); // continue speaking other task;
        }
    }

    clearTask = (stop: boolean) => {
        this.taskStack = [];
        if (stop) {
            this.stop();
        }
    }
    stop = () => {
        this.source?.stop();
    }
}

export type {
    TextSpeaker
}

const textSpeaker = new TextSpeaker();
export default textSpeaker;