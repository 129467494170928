import axios from "axios";
import {host} from "./host";


const voiceToText = async (base64:string)=>{
    const res = await axios.post(`${host}/api/voiceToText`,{
        base64
    },{
        headers:{
            "Authorization":`Bearer ${window.__enmooy__.token}`
        }
    })
    return res.data as string
}


const textToVoice = async (text:string,language:string,tone:string)=>{
    const res = await axios.post(`${host}/api/textToVoice`,{
        text,
        tone,
        language,
    },{
        responseType: 'arraybuffer',
        headers:{
            "Authorization":`Bearer ${window.__enmooy__.token}`
        }
    })
    return res.data as ArrayBuffer;
}


export {
    voiceToText,
    textToVoice
}