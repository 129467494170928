type Callback = (data: any, source: string) => void


class EventCenter {
    private eventMap: Map<string, Callback[]> = new Map()

    on(eventName: string, callback: Callback) {
        const callbacks = this.eventMap.get(eventName) || []
        callbacks.push(callback)
        this.eventMap.set(eventName, callbacks)
        return () => this.off(eventName, callback)
    }

    off(eventName: string, callback: Callback) {
        const callbacks = this.eventMap.get(eventName) || []
        const index = callbacks.indexOf(callback)
        if (index !== -1) {
            callbacks.splice(index, 1)
        }
    }

    emit(eventName: string, data: any, source: string) {
        const callbacks = this.eventMap.get(eventName) || []
        callbacks.forEach((callback) => {
            callback(data, source)
        })
    }
}

export default new EventCenter()