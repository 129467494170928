import React from 'react'
import {IonIcon, IonPage, IonRouterOutlet} from "@ionic/react";
import {Redirect, Route} from "react-router-dom";

import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import {RouteChildrenProps} from "react-router";
import Chat from "./Chat";
import {chatbubbleEllipsesOutline,personOutline} from "ionicons/icons"

interface Props {
    tabKey: string
}

interface State {
    currentTab: string
}

class Home extends React.Component<RouteChildrenProps<Props>, State> {
    constructor(props: RouteChildrenProps<Props>) {
        super(props);
        this.state = this.getInitState()
        console.log("home constructor")
    }

    getInitState = () => {
        return {
            currentTab:"chat"
        } as State
    }

    componentDidMount() {
        console.log("home didMount")

    }

    handleTabsClick = (key:string)=>{
        this.setState({currentTab:key})
        this.props.history.push(`/home/${key}`)
    }

    render() {

        return <IonPage>
            <IonRouterOutlet style={{height:"calc(100% - 16vw)"}}>
                <Route exact path="/home/chat" render={() => <Chat/>}>
                </Route>
                <Route exact path="/home/tab2" render={() => <Tab2/>}>
                </Route>
                <Route exact path="/home/tab3" render={() => <Tab3/>}>
                </Route>
                <Route exact path="/home">
                    <Redirect to="/home/chat"/>
                </Route>
            </IonRouterOutlet>
            <div className={"z-10 absolute bottom-0 flex"} style={{height:"16vw"}}>
                <div className={"w-[25vw] flex justify-center items-center"} onClick={()=>this.handleTabsClick("chat")}>
                    <IonIcon icon={chatbubbleEllipsesOutline} className={"w-[10vw] h-[10vw]"} style={{color:this.state.currentTab==="chat"?"#09c044":"grey"}} />
                </div>
                <div className={"w-[25vw] flex justify-center items-center"} onClick={()=>this.handleTabsClick("mine")} style={{color:this.state.currentTab==="mine"?"#09c044":"grey"}}>
                    <IonIcon icon={personOutline} className={"w-[10vw] h-[10vw]"} />
                </div>
                <div>

                </div>
                <div>

                </div>
            </div>
        </IonPage>
    }

}


export default Home