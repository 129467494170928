import axios from "axios";
import {host} from "./host";
import {VIP} from "../dts/vip";


const getVipInfo = async (user:number) => {
    const res = await axios.get(`${host}/api/vip/get`,{
        params:{
            user
        }
    })

    return res.data as VIP
}

export default getVipInfo