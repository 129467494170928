import React from 'react'
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";

interface Props {

}

interface State {

}

class Tab2 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        console.log("tab2 mount")
    }

    render() {
        return <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Tab 2</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Tab 2</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div>
                    page2
                </div>
            </IonContent>
        </IonPage>
    }
}

export default Tab2