import {IonToast} from "@ionic/react";
import React from "react";
import {createRoot} from "react-dom/client"
import * as ReactDOM from "react-dom";
import "./toast.scss"

interface ToastConfig {
    msg: string;
    duration: number;
    position: "top" | "middle" | "bottom";
}

const displayToast = (config: ToastConfig) => {

    const div = document.createElement("div");
    document.body.appendChild(div);
    const root = createRoot(div);
    root.render(<IonToast isOpen={true}
                          cssClass={'custom-toast'}
                          message={config.msg} onDidDismiss={() => {
        ReactDOM.unmountComponentAtNode(div)
        div.remove()
    }} duration={config.duration} position={config.position}/>)

}

export default displayToast;
