
import React from 'react'
import displayToast from "../../utils/displayToast";
import axios from "axios";
import {Button, Input, Typography} from "@douyinfe/semi-ui";
import displayAlert from "../../utils/displayAlert";
import eventCenter from "../../eventCenter";
import {IonPage} from "@ionic/react";
import {RouteChildrenProps} from "react-router";
import {Drivers, Storage} from "@ionic/storage";
import {EnmooyUser} from "../../dts/user";
import {VIP} from "../../dts/vip";
import getVipInfo from "../../network/vip";
import {host} from "../../network/host";



interface Props {

}

interface State {
    isError: boolean,
    telNum: string
    captcha: string,
    loginLoading: boolean
    registerLoading: boolean,
    captchaTimer: number

    logined: "unknown" | "true" | "false"
}

class Login extends React.Component<RouteChildrenProps<Props>, State> {
    constructor(props: RouteChildrenProps<Props>) {
        super(props);
        console.log("login")

        this.state = {
            isError: false,
            telNum: "",
            captcha: "",
            loginLoading: false,
            registerLoading: false,
            captchaTimer: 0,
            logined: "unknown"
        }
    }


    componentDidMount() {
        (async () => {
            const storage = new Storage({
                name: "enmooy_im",
                driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
            });
            await storage.create();
            window.__enmooy__.storage = storage;
            const userInfo = await storage.get("userInfo");
            const token = await storage.get("token");
            const vip = await storage.get("vip")
            if (userInfo && token) {
                await this.initEnmooy(userInfo, token,vip)
                this.setState({logined: "true"})
                this.props.history.push("/home")
            } else {
                this.setState({logined: "false"})
            }
        })()
    }

    initEnmooy = async (userInfo: EnmooyUser, token: string,vip:VIP) => {
        window.__enmooy__.userInfo = userInfo;
        window.__enmooy__.token = token;
        window.__enmooy__.vip = vip

        const storage = window.__enmooy__.storage!;
        await storage.set("userInfo", userInfo);
        await storage.set("token", token);
        await storage.set("vip",vip)
        eventCenter.emit("change:userInfo", userInfo, "login")
        eventCenter.emit("change:vip", vip, "login")
    }


    sendCaptchaCodeToPhone = async (telNum: string, ticket: string, randStr: string) => {

        const formData = new FormData()
        formData.append("ticket", ticket);
        formData.append("tel_num", telNum);
        formData.append("randstr", randStr)
        const res = await axios.post(host + "/api/sendCode", {
            ticket, phone: telNum, randstr: randStr
        });
        if (res.status !== 200) {
            displayAlert({header: res.data, buttons: ["好的"]});
        }
        return res.data;
    }

    handleLogin = async () => {
        if (this.state.telNum && this.state.captcha) {
            try {
                this.setState({loginLoading: true});
                const {userInfo, token} = (await axios.post(host + "/api/login", {
                    telNum: this.state.telNum,
                    captcha: this.state.captcha
                })).data;
                const vip = await getVipInfo(userInfo.account.id)
                this.setState({isError: false})
                await this.initEnmooy(userInfo, token,vip)
                this.props.history.push("/home")
            } catch (e: any) {
                console.error(e)
                displayToast({
                    msg: e.response.data || "未知错误",
                    duration: 1500,
                    position: "middle"
                });
                this.setState({isError: true});
            }
            this.setState({loginLoading: false});
        } else {
            displayAlert({
                header: "手机号或验证码不能为空",
                // message:"手机号或验证码不能为空",
                buttons: ["好的"],
            });
        }

    }

    callTencentCaptcha = async () => {
        if (!this.checkInput(true, false)) {
            return
        }
        const script = document.createElement("script");
        script.src = " https://ssl.captcha.qq.com/TCaptcha.js"
        document.body.prepend(script);
        await new Promise<void>(resolve => {
            const timer = setInterval(() => {
                //@ts-ignore
                if (window.TencentCaptcha) {
                    clearInterval(timer);
                    resolve();
                }
            }, 500)
        })

        const [ticket, randStr] = await new Promise<any>((resolve, reject) => {
            //@ts-ignore
            const tencentCode = new window.TencentCaptcha('2021181129', (res: any) => {
                if (res.ret === 0) {
                    resolve([res.ticket, res.randstr]);
                } else {
                    console.error(res);
                    reject(res);
                }
            });
            tencentCode.show();
        });
        await this.sendCaptchaCodeToPhone(this.state.telNum, ticket, randStr);
        this.setState({captchaTimer: 60});
        const timer = setInterval(() => {
            if (this.state.captchaTimer >= 1) {
                const nextTimer = this.state.captchaTimer - 1;
                this.setState({captchaTimer: nextTimer});
                if (nextTimer === 0) {
                    clearInterval(timer)
                }
            }
        }, 1000)
        script.remove();
    }

    checkInput = (telNum: boolean, captcha: boolean) => {
        if ((!telNum || this.state.telNum) && (!captcha || this.state.captcha)) {
            return true
        }
        displayAlert({
            header: "手机号或验证码不能为空",
            // message:"手机号或验证码不能为空",
            buttons: ["好的"],
        });
        return false
    }

    render() {
        return <IonPage>
            {
                this.state.logined === "false" && <div>
                    <div className={'min-w-[61.8%] pt-[30%] h-full flex items-center justify-center'}>
                        <div className={'h-fit flex flex-col  items-center px-[24px] w-[356px]'}>
                            <Typography.Title heading={4}
                                              style={{marginBottom: 26}}>登录</Typography.Title>
                            <Input placeholder={"手机号"} size={'large'} showClear={true} spellCheck={false}
                                   className={"bg-semi-color-bg-2 mb-[18px]"} value={this.state.telNum}
                                   onChange={v => this.setState({telNum: v})}
                                   suffix={(() => {
                                       const canSend = this.state.captchaTimer <= 0;
                                       return <Typography.Text onClick={() => {
                                           if (canSend) {
                                               this.callTencentCaptcha();
                                           }
                                       }} link={canSend} type={'tertiary'}
                                                               style={{width: canSend ? "80px" : "30px"}}>{this.state.captchaTimer <= 0 ? "发送验证码" : this.state.captchaTimer}</Typography.Text>
                                   })()}
                            ></Input>
                            <Input size={'large'} showClear={true}
                                   placeholder={"验证码"}
                                   spellCheck={false}
                                   validateStatus={this.state.isError ? 'error' : 'default'}
                                   style={{marginBottom: 30}}
                                   value={this.state.captcha}
                                   onChange={v => this.setState({captcha: v})}
                                   onEnterPress={this.handleLogin}
                            ></Input>
                            <Button type={'primary'} theme={"solid"} size={'large'} block={true} style={{marginBottom: 8}}
                                    onClick={this.handleLogin} loading={this.state.loginLoading}>登录</Button>
                        </div>
                    </div>
                </div>
            }
        </IonPage>
    }

}


export default Login


