import axios from "axios";
import {host} from "./host";


interface Message {
    role: string
    content: string
}

export type {
    Message
}

interface Reply {
    id: string
    object: string
    created: number,
    choices: {
        index: number
        message: Message,
        finish_reason: string
    }[],
    usage: {
        prompt_tokens: number
        total_tokens: number
        completion_tokens: number
    }
}

const getReply = async (messages: Message[], onData: (text: string,done:string) => void) => {
    const res = await fetch(`${host}/api/sendToChatGPT`, {
        "headers": {
            "authorization": `Bearer ${window.__enmooy__.token}`,
            "content-type": "application/json",
        },
        "body": JSON.stringify({
            messages,
            args: {
                stream: true
            }
        }),
        "method": "POST",
        "mode": "cors",
        // "credentials": "include"
    });
    const writeStream = new WritableStream({
        write(chunk) {
            let text = new TextDecoder().decode(chunk)
            const textList = text.split("data: ")
            let chatGPTReply = "";
            let returnFlag = false;
            for (let text of textList) {
                text = text.trim()
                if(text){
                    if(text==="[DONE]"){
                        returnFlag = true;
                    }else{
                        try{
                            const res = JSON.parse(text)
                            if (res.choices) {
                                chatGPTReply += res?.choices[0]?.delta?.content ?? ""
                                if (res.choices[0].finish_reason === "length") {
                                    returnFlag = true;
                                    chatGPTReply += "\n...\n\n\n\n"
                                    chatGPTReply += "对话结束，前几轮对话长度总和超过限制，推荐再次询问。"
                                    onData(chatGPTReply, "length")
                                    return
                                }
                            }else if(text.includes("context_length_exceeded")){
                                returnFlag = true;
                                chatGPTReply += "\n...\n\n\n\n"
                                chatGPTReply += "对话结束，前几轮对话长度总和超过限制，推荐再次询问。"
                                onData(chatGPTReply, "length")
                                return;
                            }
                        }catch (e){
                            console.log("Failed to parse chatGPT reply: ",text)
                        }
                    }

                }
            }
            if (returnFlag) {
                onData(chatGPTReply,"end")
                return
            }else{
                onData(chatGPTReply,"")
            }

        }
    })
    await res.body!.pipeTo(writeStream)
}

export default getReply