import {IonAlert} from "@ionic/react";
import React from "react";
import {createRoot} from "react-dom/client"
import * as ReactDOM from "react-dom";
import {AlertButton} from "@ionic/core/dist/types/components/alert/alert-interface";

interface AlertConfig {
    header?: string;
    subHeader?: string;
    message?: string;
    buttons: (AlertButton | string)[];
    onButtonClick?: () => void;
}

const displayAlert = (config: AlertConfig) => {

    const div = document.createElement("div");
    document.body.appendChild(div);
    const root = createRoot(div);
    root.render(<IonAlert
        isOpen={true}
        onDidDismiss={() => {
            ReactDOM.unmountComponentAtNode(div)
            div.remove()
        }
        }
        {
            ...config
        }
    />)

}

export default displayAlert;