import React from 'react'
import {IonAvatar} from "@ionic/react";



interface Props{
    text: string
}
interface State{

}

class MessageItem extends React.Component<Props, State>{
    constructor(props:Props) {
        super(props);

    }

    render() {
        return (
            <div className={"mb-4 flex justify-end"}>
                <div className={"flex w-full flex-row-reverse max-w-[80%]"}>
                    {/*<div>*/}
                    {/*    <IonAvatar>*/}
                    {/*        <img alt="Silhouette of a person's head" src="http://192.168.2.74:8000/avatar.svg" />*/}
                    {/*    </IonAvatar>*/}
                    {/*</div>*/}
                    <div className={"mr-2 text-white p-3 rounded-md break-all whitespace-pre-wrap"} style={{background:"#5b70fd"}}>
                        <div style={{lineHeight:'1.3em'}}>
                            {this.props.text}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default MessageItem