import React from 'react'
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";

interface Props {

}

interface State {

}

class Tab3 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <IonPage>
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Tab 3</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">Tab3</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <div>
                        page3
                    </div>
                </IonContent>
            </IonPage>
        </IonPage>
    }
}

export default Tab3