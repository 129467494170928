import {Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import "./theme/tailwind.css";
import React from "react";
import Home from "./pages/Home";
import VConsole from "vconsole";
import eventCenter from "./eventCenter";
import {get, nth} from "lodash-es";
import {EnmooyUser} from "./dts/user";
import {VIP} from "./dts/vip";
import {Storage} from "@ionic/storage";
import Login from "./pages/Login";
import {Redirect} from "react-router";
import type {TextSpeaker} from "./utils/TextSpeaker";
import textSpeaker from "./utils/TextSpeaker";

setupIonicReact({
    mode: 'ios',
});


declare global {
    interface Window {
        __enmooy__: {
            recognition: SpeechRecognition|null,
            userInfo:EnmooyUser|null
            token:string|null
            vip:VIP|null
            storage:Storage|null,
            textSpeaker: TextSpeaker
        };
    }
}

interface Props {

}

interface State {

}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.init()

    }


    componentDidMount() {
        // new VConsole();
        //


    }

    init = async () => {
        window.__enmooy__ = {
            userInfo: null,
            storage:null,
            token:null,
            vip:null,
            recognition:null,
            textSpeaker: textSpeaker
        }
        if(window.webkitSpeechRecognition){
            const recognition = new webkitSpeechRecognition();
            recognition.continuous = true;
            recognition.interimResults = false;
            recognition.lang = 'cmn-Hans-CN'; //普通话 (中国大陆)
            let lastText = "";
            recognition.onresult = (event)=>{
                const res = nth(event.results,-1)![0]
                const {confidence,transcript} = res!;
                if(confidence>0.7 && lastText!==transcript){
                    lastText = transcript;
                    eventCenter.emit("change:recognition:text",res,"app")
                }
            }
            eventCenter.on("change:recognition:state:start",()=>{
                recognition.start()
            })
            eventCenter.on("change:recognition:state:stop",()=>{
                recognition.stop()
            })
            window.__enmooy__.recognition = recognition
        }
    }


    render() {
        return <IonApp>
            <IonReactRouter>
                <IonRouterOutlet mode={"ios"}>
                    <Route exact path="/">
                        <Redirect to="/login"/>
                    </Route>
                    <Route path="/home" component={Home}/>
                    <Route path="/login" component={Login}/>

                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    }
}

export default App;
