import React, {TouchEventHandler} from 'react'
import {
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonLabel,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import displayAlert from "../../../utils/displayAlert";
import {textToVoice, voiceToText} from "../../../network/voice";
import getReply, {Message} from "../../../network/chatGPT";
import eventCenter from "../../../eventCenter";
import {cloneDeep, debounce, flatten, isEqual} from "lodash-es";
import FromMe from "./Round/FromMe";
import FromOther from "./Round/FromOther";
import {micCircle} from "ionicons/icons";
import {Button, Input, Tooltip} from "@douyinfe/semi-ui";
import {IconSend} from "@douyinfe/semi-icons"
import Round from "./Round";

interface Props {

}

interface State {
    history: [Message,Message][],
    recording: boolean,
    waiting: boolean,
    hi: boolean,
    userInput:string,
    contextEndIndex: number,
    speakingTexts: string[]
}

class ChatPage extends React.Component<Props, State> {

    history: [Message,Message][] = []
    mediaRecorder: MediaRecorder | undefined

    speakingStatus:{
        historyIndex:number,
        textIndex:number,
    } = {
        historyIndex:-1,
        textIndex:0
    }


    constructor(props: Props) {
        super(props);
        this.state = {
            history: [],
            recording: false,
            waiting: false,
            hi: false,
            userInput:"",
            contextEndIndex: -1,
            speakingTexts:[]
        }
    }

    componentDidMount() {
        navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then((stream) => {

            })
            .catch((err) => {
                displayAlert({
                    header: "错误",
                    message: "当前设备不支持录音，请更换设备后重试",
                    buttons: ["好的"]
                })
            });
        eventCenter.on("change:recognition:text", async res => {
            const {transcript, confidence} = res;
            console.log(transcript)
            if (transcript.includes("我的助理")) {
                await this.handleRecordStart()
                console.log('start record')
                // const cancel = debounce(()=>{
                //     console.log("end record")
                //     this.handleRecordStop()
                // },3000)
                eventCenter.on("change:recognition:text", async res => {
                    const {transcript, confidence} = res;
                    console.log(transcript)
                    this.handleRecordStop()
                })
            }
        })
    }

    handleRecordStart = async (e?:React.TouchEvent<HTMLDivElement>) => {
        e?.preventDefault();
        e?.stopPropagation();
        console.log("touchStart")
        if (!navigator.mediaDevices) {
            displayAlert({
                header: "错误",
                message: "当前设备不支持录音，请更换设备后重试",
                buttons: ["好"],
            })
            return;
        }
        window.navigator?.vibrate?.(100)
        const stream = await navigator.mediaDevices.getUserMedia(
            // constraints - only audio needed for this app
            {
                audio: true,
            }
        )

        const mediaRecorder = new MediaRecorder(stream);
        const chunks: BlobPart[] | undefined = [];
        mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
        }

        mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, {'type': 'audio/ogg; codecs=opus'});
            const reader = new FileReader();
            let base64 = ""
            reader.onload = async () => {
                base64 = reader.result as string;
                const text = await voiceToText(base64.slice("data:audio/ogg; codecs=opus;base64,".length))
                this.handleSendToChatGPT(text)
            };
            reader.readAsDataURL(blob);
        }

        mediaRecorder.start();
        this.mediaRecorder = mediaRecorder
        const mouseUp = () => {
            window.navigator?.vibrate?.(100)
            this.handleRecordStop()
            window.removeEventListener("touchend", mouseUp)
        }
        window.addEventListener("touchend",mouseUp)
        this.setState({recording: true})
    }

    handleRecordStop = () => {
        if (this.mediaRecorder?.state === "recording") {
            this.mediaRecorder?.stop()
            this.setState({recording: false})
        }
    }


    handleSendToChatGPT = async (text: string) => {
        this.history.push([{role:"user",content:text},{role:"assistant",content:""}]);
        this.setState({history:this.history})
    }

    handleHiChange = (v: boolean) => {
        if (v) {
            eventCenter.emit("change:recognition:state:start", true, "chat");
        } else {
            eventCenter.emit("change:recognition:state:stop", false, "chat");
        }
        this.setState({hi: v})
    }

    handleSendButtonClick = ()=>{
        const text = this.state.userInput;
        if(text){
            this.handleSendToChatGPT(text)
            this.setState({userInput:""})
        }
    }

    handleReplyUpdate = (text:string,i:number,force:boolean)=>{
        if(i === this.history.length - 1){
            if(this.speakingStatus.historyIndex !== i) {
                window.__enmooy__.textSpeaker.clearTask(true);
                this.speakingStatus = {
                    historyIndex: i,
                    textIndex: 0,
                }
            }
            if([".","?","!",";","。","？","；","！"].some(v=>text.endsWith(v)) || force){
                const speakText = text.slice(this.speakingStatus.textIndex);
                console.log("addTask",speakText)
                window.__enmooy__.textSpeaker.addTask(speakText);
                this.speakingStatus.textIndex = text.length;
            }
        }
        this.debouncedClearContext();
    }

    debouncedClearContext = debounce(()=>{
        this.setState({contextEndIndex:this.history.length-1});
    },1000*60*5)

    render() {
        return <IonPage>
                <div className={'flex flex-col bg-[#F5F5F5FF]'} style={{height:`calc(100% - 12vw)`}}>
                    <div className={"flex mt-[2vw]"}>
                        <div className={"flex items-center ml-auto pr-[4vw]"}>
                            <div style={{color:"gray"}} className={"mr-2"}>我的助理</div>
                            <IonCheckbox  slot="start" checked={this.state.hi} onIonChange={v => this.handleHiChange(v.target.checked)}/>
                        </div>
                    </div>

                    <div className={"w-full h-full flex-1 overflow-auto pb-[20vw]"} style={{overflow:"overlay"}} id={"chatContainer"}>
                        {this.state.history.map((messages, i) => {
                            return <Round history={this.state.history.slice(this.state.contextEndIndex+1,i)} userText={messages[0].content}
                                          onOverflow={()=>{
                                              this.setState({contextEndIndex:i})
                                          }}
                                          onReply={(replyText,done)=>{
                                this.history[i][1] = {role:"assistant",content:replyText}
                                this.setState({history:cloneDeep(this.history)})
                                this.handleReplyUpdate(replyText,i,done)
                            }}/>
                        })}
                    </div>
                   <div className={"fixed bottom-[0vw] w-full"}>
                       <Tooltip content={"录音中"} position={"top"} trigger={"custom"} visible={this.state.recording}>
                           <div className={'w-full h-fit flex justify-center mb-[1vw]'} style={{touchAction:"none"}}>
                               <div
                                   onTouchStart={this.handleRecordStart}
                                   onContextMenu={e=>e.preventDefault()}
                                   className={"rounded cursor-pointer bg-white w-fit h- w-[20vw] h-[20vw] rounded-full"}>
                                   <IonIcon icon={micCircle} className={"w-full h-full"} size={"large"} style={{color:this.state.recording?"#d06212":"#145fd2"}}></IonIcon>
                               </div>
                           </div>
                       </Tooltip>
                       <div className={"flex px-[2vw] py-[1vw] bg-white"}>
                           <Input size={"large"} value={this.state.userInput} onChange={v=>this.setState({userInput:v})}/>
                           <Button size={"large"} onClick={this.handleSendButtonClick} type={"primary"} theme={"solid"} style={{marginLeft:"2vw",width:"12vw"}} icon={<IconSend />}/>
                       </div>
                   </div>
                </div>
        </IonPage>
    }
    }

    export default ChatPage