import React from "react"
import getReply, {Message} from "../../../../network/chatGPT";
import FromMe from "./FromMe";
import FromOther from "./FromOther";
import {cloneDeep, flatten} from "lodash-es";

interface Props {
    history:[Message,Message][],
    userText:string,
    onReply:(chatGPTReply:string,done:boolean)=>void,
    onOverflow:()=>void;
}

interface State{
    reply:string,
    nowState:"requesting"|"replying"|"done",
}

class Round extends React.Component<Props, State>{

    constructor(props:Props) {
        super(props);
        this.state = {
            reply:"",
            nowState:"requesting"
        }
    }

    componentDidMount() {
        this.requestChatGPT();
    }

    requestChatGPT = async ()=>{
        const content = [...flatten(this.props.history),{role:"user",content:this.props.userText}];
        await getReply(content,(text,done)=>{
            this.setState({reply:this.state.reply + text,nowState:"replying"});
            this.props.onReply(this.state.reply+text,Boolean(done));
            if(done){
                this.setState({nowState:"done"});
            }
            if(done==="length"){
                this.props.onOverflow();
            }
        })
    }

    render() {
        return (
            <div>
                <FromMe text={this.props.userText}/>
                <FromOther text={this.state.nowState ==="requesting" ? "🤔...请稍候":this.state.reply}/>
            </div>
        );
    }
}

export default Round